import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

import Container from '@objects/container'
import YouTube from '@objects/youtube'
import Image from '@objects/image'
import TextModule from '@components/text'
import CustomBigTeaser from './teaser'
import InViewAnimation from '@objects/inViewAnimation'
import PictureGallery, { PictureGalleryProps } from '@components/pictureGallery'
import Box, { BoxProps } from './box'

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      /**
       * Workaround:
       * When the PictureGallery is open,
       * the fixed video is no longer visible
       */
      overflow: 'visible !important',
    },
  },
  root: {
    position: 'relative',
  },
  headline: {
    fontSize: 40,
    textAlign: 'center',
    color: theme.palette.text.invert,
  },
  background: {
    position: 'sticky',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 0,
    [theme.breakpoints.up('lg')]: {
      top: 65,
    },
  },
  content: {
    position: 'relative',
    minHeight: '100vh',
    zIndex: 1,
    color: '#333',
    padding: '64px 0',
    marginTop: 'calc(-100vh)',
    [theme.breakpoints.up('lg')]: {
      minHeight: 'calc(100vh - 65px)',
      marginTop: 'calc(-100vh + 65px)',
    },
  },
  centered: {
    textAlign: 'center',
  },
  lightText: {
    color: theme.palette.text.invert,
  },
  blur: {
    '& > div:not(.carouselIsOpen)': {
      background: 'rgba(0, 0, 0, 0.4)',
      backdropFilter: 'blur(16px)',
      color: theme.palette.text.invert,
    },
  },
  pictureGallery: {
    '& .swiper': {
      background: 'rgba(0, 0, 0, 0.4)',
      backdropFilter: 'blur(16px)',
      color: theme.palette.text.invert,
    },
    '& .captionIsVisible': {
      padding: '0 8px',
    },
  },
  image: {
    '& .gatsby-image-wrapper + div': {
      padding: '8px',
      color: theme.palette.text.invert,
    },
  },
  youtube: {
    margin: '80px auto',
  },
}))

export type ContentProps = {
  content: RvG.Contentful.ContentfulModuleScrolltytellingContent[]
  onThumbnailClick?: () => void
} & React.PropsWithChildren<unknown>

export default function Content({
  content,
  onThumbnailClick,
  children,
}: ContentProps): ReactElement {
  const classes = useStyles()

  function ContentComponent({
    type,
    props,
  }: {
    type: string
    props: RvG.Contentful.ContentfulModuleScrolltytellingContent
  }): ReactElement {
    switch (type) {
      case 'ContentfulModuleScrollytellingTextbox':
        const boxProps =
          props as RvG.Contentful.IContentfulModuleScrolltytellingTextbox
        const sizeMap = {
          Small: 's',
          Medium: 'm',
          Large: 'l',
          'Extra-Large': 'xl',
        }

        return (
          <Container type="nomargin">
            <Box
              text={boxProps.text?.text}
              size={sizeMap[boxProps.size || 'Medium'] as BoxProps['size']}
              position={
                (
                  boxProps.position || 'center'
                )?.toLowerCase() as BoxProps['position']
              }
              isQuote={boxProps.isQuote}
            />
          </Container>
        )
      case 'ContentfulImage':
        const imageProps = props as RvG.Contentful.IImageRef

        return (
          <Container type="nomargin" key={type}>
            <InViewAnimation>
              <div className={clsx(classes.image, classes.blur)}>
                <Image {...imageProps} image={imageProps.image?.fluid} />
              </div>
            </InViewAnimation>
          </Container>
        )
      case 'ContentfulModuleBigTeaser':
        const teaserProps = props as RvG.Contentful.IContentfulModuleBigTeaser
        if (!teaserProps.image) return <></>

        return (
          <InViewAnimation>
            <CustomBigTeaser
              {...teaserProps}
              light={teaserProps.theme === 'Light'}
            />
          </InViewAnimation>
        )
      case 'ContentfulModulePictureGallery':
        return (
          <Container type="nomargin">
            <InViewAnimation>
              <div className={clsx(classes.pictureGallery, classes.blur)}>
                <PictureGallery
                  {...({
                    ...props,
                    observer: true,
                    observeParents: true,
                    autoHeight: true,
                  } as PictureGalleryProps)}
                />
              </div>
            </InViewAnimation>
          </Container>
        )
      case 'ContentfulModuleText':
        const textProps = props as RvG.Contentful.IContentfulModuleText

        return (
          <Container type="nomargin">
            <InViewAnimation>
              <div className={clsx(classes.lightText, classes.centered)}>
                <TextModule copy={textProps.copy} />
              </div>
            </InViewAnimation>
          </Container>
        )
      case 'ContentfulYouTubeItem':
        return (
          <Container type="nomargin">
            <InViewAnimation>
              <YouTube
                className={classes.youtube}
                onThumbnailClick={onThumbnailClick}
                {...props}
              />
            </InViewAnimation>
          </Container>
        )
      default:
        return <></>
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.background}>{children}</div>

      <div className={classes.content}>
        {content.map((c, i) => {
          return <ContentComponent key={i} type={c.__typename} props={c} />
        })}
      </div>
    </div>
  )
}
