import React, { ReactElement, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
  },
  video: {
    width: '100%',
    opacity: 0,
    transition: `opacity 0.2s ${theme.transitions.easing.easeInOut}`,
  },
  full: {
    objectFit: 'cover',
    height: 'calc(100vh)',
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 65px)',
    },
  },
  loaded: {
    opacity: 1,
  },
}))

export type BackgroundVideoProps = {
  video: string
  videoSmall?: string
  muted: boolean
  full?: boolean
  onLoad?: () => void
}

export default React.forwardRef(function BackgroundVideo(
  { video, muted, full, onLoad }: BackgroundVideoProps,
  videoRef
): ReactElement {
  const classes = useStyles()
  const [loaded, setLoaded] = useState<boolean>(false)
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '1200px',
  })

  function onLoadedData() {
    setLoaded(true)
    if (onLoad) {
      onLoad()
    }
  }

  useEffect(() => {
    setLoaded(false)
  }, [video])

  return (
    <div className={classes.root} ref={ref}>
      {inView && (
        <video
          ref={videoRef}
          className={clsx(classes.video, {
            [classes.full]: full,
            [classes.loaded]: loaded,
          })}
          poster=""
          key={video}
          controls={false}
          muted={muted}
          loop
          playsInline
          autoPlay
          onLoadedData={onLoadedData}
        >
          <source src={video} />
        </video>
      )}
    </div>
  )
})
