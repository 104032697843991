import React, { ReactElement, useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useInView } from 'react-intersection-observer'

import Container from '@objects/container'
import Icon from '@objects/icon'
import InViewAnimation from '@objects/inViewAnimation'
import Copy from '@components/copy'
import SoundOn from '../static/icons/sound-on.svg'
import SoundOff from '../static/icons/sound-off.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100dvh)',
    },
  },
  fullHeight: {
    // This class is set only temperory, until the background video/image is loaded
    // Otherwise the page jumps when the background is loaded
    [theme.breakpoints.down('lg')]: {
      height: '100dvh',
    },
    [theme.breakpoints.up('lg')]: {
      aspectRatio: '16 / 9',
    },
  },
  content: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  blur: {
    background: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(16px)',
  },
  muteToggle: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    margin: `${theme.spacing(8)}px 0 0 0`,
    display: 'inline-flex',
    gap: theme.spacing(4),
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    cursor: 'pointer',
    userSelect: 'none',
    border: '0',
  },
  muteToggleIconWithText: {
    padding: theme.spacing(3, 4),
    margin: `${theme.spacing(6)}px 0`,
    borderRadius: theme.spacing(10),
    fontSize: '10px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  muteToggleIcon: {
    borderRadius: '50%',
    aspectRatio: '1',
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
  },
  typo: {
    color: theme.palette.text.invert,
  },
  headline: {
    margin: `0 0 ${theme.spacing(2)}px 0`,
  },
  copy: {
    fontSize: '20px',
  },
  boxOuter: {
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      bottom: '100px',
      left: theme.spacing(4),
      right: theme.spacing(4),
    },
  },
  boxOuterSmall: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '340px',
    },
  },
  boxOuterMedium: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%',
    },
  },
  boxOuterLarge: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  boxOuterCenter: {
    [theme.breakpoints.up('md')]: {
      margin: '0 auto',
    },
  },
  boxOuterRight: {
    [theme.breakpoints.up('md')]: {
      margin: '0 0 0 auto',
    },
  },
  box: {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(4),
    color: theme.palette.text.invert,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8),
    },

    '& p': {
      fontSize: '20px',
      marginBottom: theme.spacing(3),

      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  scrollDown: {
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    margin: `0 0 ${theme.spacing(6)}px 0`,
    fontWeight: 600,
    backgroundColor: 'transparent',
    border: '0',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.up('lg')]: {
      margin: `0 0 ${theme.spacing(12)}px 0`,
      cursor: 'pointer',
    },
  },
  inView: {
    opacity: 1,
  },
}))

export type TopAreaProps = {
  copy?: RvG.Contentful.BasicRichTextType
  copyPosition?: 'Left' | 'Center' | 'Right'
  copySize?: 'Small' | 'Medium' | 'Large'
  scrollDown: () => void
  scrollDownLabel?: string
  soundFile?: RvG.Contentful.IAsset
  soundLabelOn?: string
  soundLabelOff?: string
  loaded?: boolean
} & React.PropsWithChildren<unknown>

export default function TopArea({
  copy,
  copyPosition,
  copySize,
  scrollDown,
  scrollDownLabel,
  soundFile,
  soundLabelOn,
  soundLabelOff,
  loaded,
  children,
}: TopAreaProps): ReactElement {
  const classes = useStyles()
  const audioRef = useRef<HTMLAudioElement>(null)
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const [muted, setMuted] = useState<boolean>(true)

  useEffect(() => {
    if (!inView && !muted) {
      setMuted(true)
    }
  }, [inView])

  useEffect(() => {
    if (muted) {
      audioRef?.current?.pause()
    } else {
      audioRef?.current?.play()
    }
  }, [muted])

  return (
    <div
      ref={ref}
      className={clsx(classes.root, !loaded && classes.fullHeight)}
    >
      {!!soundFile?.file?.url && (
        <audio ref={audioRef} src={soundFile?.file.url} loop />
      )}
      {children}
      <div className={classes.content}>
        {!!soundFile?.file?.url && (
          <InViewAnimation className={classes.inView}>
            <button
              className={clsx(
                classes.typo,
                classes.blur,
                classes.muteToggle,
                !soundLabelOn && !soundLabelOff
                  ? classes.muteToggleIcon
                  : classes.muteToggleIconWithText
              )}
              onClick={() => setMuted(!muted)}
            >
              <img src={muted ? SoundOff : SoundOn} />
              {muted ? soundLabelOn : soundLabelOff}
            </button>
          </InViewAnimation>
        )}
        <Container type="nomargin" className={clsx(classes.container)}>
          {!!copy && (
            <div
              className={clsx(
                classes.boxOuter,
                copySize === 'Small' && classes.boxOuterSmall,
                copySize === 'Medium' && classes.boxOuterMedium,
                copySize === 'Large' && classes.boxOuterLarge,
                copyPosition === 'Center' && classes.boxOuterCenter,
                copyPosition === 'Right' && classes.boxOuterRight
              )}
            >
              <InViewAnimation className={classes.inView}>
                <div className={clsx(classes.box, classes.blur)}>
                  <Copy richtext={copy} />
                </div>
              </InViewAnimation>
            </div>
          )}

          {!!scrollDownLabel && (
            <button
              className={clsx(classes.typo, classes.scrollDown)}
              onClick={scrollDown}
            >
              <Icon name="ArrowDown" /> {scrollDownLabel}
            </button>
          )}
        </Container>
      </div>
    </div>
  )
}
