import React, { ReactElement } from 'react'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import { Grid } from '@material-ui/core'

import InViewAnimation from '@objects/inViewAnimation'
import Blockquote from '@objects/blockquote'

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    marginBottom: theme.spacing(16),
  },
  inner: {
    position: 'relative',
    zIndex: 2,
    color: theme.palette.text.invert,
    padding: theme.spacing(8.5),
  },
  background: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    backdropFilter: 'blur(16px)',
  },
  left: {
    justifyContent: 'flex-start',
    borderTopRightRadius: theme.spacing(4),
    borderBottomRightRadius: theme.spacing(4),
    transform: 'translateZ(0)',
    '& $background': {
      transform: 'skewX(6deg) translateX(5%)',
      borderTopLeftRadius: theme.spacing(4),
      borderBottomLeftRadius: theme.spacing(4),
    },
    '& $inner': {
      padding: theme.spacing(8.5, 12),
      transform: 'translateX(5%)',
    },
  },
  center: {
    justifyContent: 'center',
    '& $background': {
      borderRadius: theme.spacing(4),
    },
  },
  right: {
    justifyContent: 'flex-end',
    borderTopLeftRadius: theme.spacing(4),
    borderBottomLeftRadius: theme.spacing(4),
    transform: 'translateZ(0)',
    '& $background': {
      transform: 'skewX(-6deg) translateX(-5%)',
      borderTopRightRadius: theme.spacing(4),
      borderBottomRightRadius: theme.spacing(4),
    },
    '& $inner': {
      padding: theme.spacing(8.5, 12),
    },
  },
  light: {
    '& $background': {
      backgroundColor: theme.palette.background.light,
      backdropFilter: 'blur(0px)',
    },
    '& $inner': {
      color: theme.palette.text.primary,
    },
  },
  s: {
    fontSize: '20px',
    lineHeight: '30px',
  },
  m: {
    fontSize: '22px',
    lineHeight: '28px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  l: {
    fontSize: '24px',
    lineHeight: '28px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '34px',
      lineHeight: '40px',
    },
  },
  xl: {
    fontSize: '28px',
    lineHeight: '34px',
    [theme.breakpoints.up('lg')]: {
      fontSize: '40px',
      lineHeight: '48px',
    },
  },
  blockquote: {
    '& blockquote': {
      paddingRight: 0,
    },
    '& p': {
      [theme.breakpoints.up('lg')]: {
        '$s &': {
          fontSize: '28px',
        },
        '$m &': {
          fontSize: '36px',
        },
      },
    },
  },
  inView: {
    opacity: 1,
    transform: 'translateY(100px)',
  },
}))

export type BoxProps = {
  className?: string
  children?: React.ReactNode
  text?: string
  isQuote?: boolean
  light?: boolean
  size: 's' | 'm' | 'l' | 'xl'
  position: 'left' | 'center' | 'right'
}

export default function Content({
  className,
  children,
  text,
  isQuote,
  light,
  size,
  position,
}: BoxProps): ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const offset = position === 'center' ? 3 : 6 // 3 center, 6 right

  function Element() {
    return (
      <InViewAnimation className={classes.inView}>
        <div
          className={clsx(
            classes.root,
            classes[position],
            {
              [classes.light]: light,
            },
            className
          )}
        >
          <div className={classes.background} />
          <div className={clsx(classes.inner, classes[size])}>
            {isQuote ? (
              <Blockquote className={classes.blockquote} text={text || ''} />
            ) : (
              text
            )}
          </div>
        </div>
      </InViewAnimation>
    )
  }

  if (children) {
    return (
      <div
        className={clsx(
          classes.root,
          classes[position],
          {
            [classes.light]: light,
          },
          className
        )}
      >
        <div className={classes.background} />
        <div className={clsx(classes.inner)}>{children}</div>
      </div>
    )
  }

  if (!text) return <></>

  return (
    <>
      {isLarge ? (
        <Grid container spacing={8}>
          {position !== 'left' && <Grid item md={offset} />}
          <Grid item xs={12} md={6}>
            <Element />
          </Grid>
        </Grid>
      ) : (
        <Element />
      )}
    </>
  )
}
