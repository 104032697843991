import React, { ReactElement, useEffect } from 'react'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import Image from '@objects/image'
import BackgroundVideo from './backgroundVideo'

const useStyles = makeStyles(() => ({
  background: {
    margin: '0',
    height: '100%',

    '& .gatsby-image-wrapper': {
      height: '100%',
    },

    '& img': {
      objectFit: 'cover !important',
    },
  },
  contentBackground: {
    '& .gatsby-image-wrapper': {
      height: '100vh',
    },
  },
}))

export type BackgroundProps = {
  video: RvG.Contentful.IAsset
  videoMobile?: RvG.Contentful.IAsset
  full?: boolean
  content?: boolean
  onLoad?: () => void
}

export default React.forwardRef(function Background(
  { video, videoMobile, full, content, onLoad }: BackgroundProps,
  videoRef
): ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  function isVideo(asset: RvG.Contentful.IAsset) {
    return asset.file?.contenttype.includes('video')
  }

  function ImageElement({
    video,
    isMobile,
  }: {
    video: RvG.Contentful.IAsset
    isMobile: boolean
  }) {
    useEffect(() => {
      if (onLoad) {
        const image = document
          .querySelector('.bg-image-scrollytelling')
          ?.querySelector('img') as HTMLImageElement

        image.onload = onLoad
      }
    }, [])

    return (
      <Image
        className={clsx(
          'bg-image-scrollytelling',
          classes.background,
          content && classes.contentBackground
        )}
        image={video.fluid}
        imgStyle={
          isMobile ? { margin: '0', objectFit: 'cover' } : { margin: '0' }
        }
      />
    )
  }

  return (
    <>
      {!!video && (isLarge || !videoMobile) ? (
        <>
          {!!video?.file && isVideo(video) && (
            <BackgroundVideo
              video={video.file?.url}
              muted
              full={full}
              ref={videoRef}
              onLoad={onLoad}
            />
          )}

          {!!video?.fluid && !isVideo(video) && (
            <ImageElement video={video} isMobile={false} />
          )}
        </>
      ) : (
        <>
          {!!videoMobile?.file && isVideo(videoMobile) && (
            <BackgroundVideo
              video={videoMobile.file?.url}
              muted
              full={full}
              ref={videoRef}
              onLoad={onLoad}
            />
          )}

          {!!videoMobile?.fluid && !isVideo(videoMobile) && (
            <ImageElement video={videoMobile} isMobile={true} />
          )}
        </>
      )}
    </>
  )
})
