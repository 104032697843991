import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

import Container from '@objects/container'
import Headline from '@objects/headline'
import Copy from '@components/copy'
import Image from '@objects/image'
import Box from './box'

export type BigTeaserProps = RvG.IReactDefaultProps & {
  type: string
  imageFocalPoint?: RvG.Contentful.imageFocalPoint
  light?: boolean
  layout?: string
  headline: string
  copy: RvG.Contentful.BasicRichTextType
  cta?: RvG.Contentful.BasicRichTextType
  ctaButton?: React.ReactNode
  image: RvG.Contentful.IAsset
  mobileImage: RvG.Contentful.IAsset
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(10, 0),
  },
  container: {
    position: 'relative',
  },
  imageWrapper: {
    width: '100%',
    '.isTrailing &': {
      textAlign: 'right',
    },
  },
  image: {
    display: 'inline-block',
    width: '100%',
    margin: 0,
    '.isTrailing &': {
      margin: '-20% 0 0 auto',
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%',
    },
  },
  contentWrapper: {
    width: '85%',
    position: 'relative',
    margin: '-10% 0 0 auto',
    zIndex: 1,
    '.isTrailing &': {
      margin: '0',
    },
    [theme.breakpoints.up('lg')]: {
      width: '55%',
      margin: '-20% 0 0 auto',
      transform: 'translateY(10%)',
      transition: `transform 0.3s ${theme.transitions.easing.easeInOut}`,
      '.isTrailing &': {
        margin: '0% 0 0 0',
      },
    },
  },
  contentInner: {
    boxSizing: 'border-box',
    padding: theme.spacing(2, 2, 2, 9),
    '.isTrailing &': {
      padding: theme.spacing(2, 9, 2, 2),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(7, 15),
      '.isTrailing &': {
        padding: theme.spacing(7, 15),
      },
    },
  },
  copy: {
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  cta: {
    marginTop: theme.spacing(4),
    '& a': {
      ...theme.mixins.linkStyleUnderlined,
      fontWeight: '500',
    },
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  headlineAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0s, transform 1s ${theme.transitions.easing.easeInOut} 0s`,
  },
  copyAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.2s, transform 1s ${theme.transitions.easing.easeInOut} 0.2s`,
  },
  ctaAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.4s, transform 1s ${theme.transitions.easing.easeInOut} 0.4s`,
  },
  inView: {
    transform: 'translateY(0%)',
    '& $copyAnim, & $headlineAnim, & $ctaAnim': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
  box: {
    [theme.breakpoints.down('md')]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      '.isTrailing &': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
}))

type TeaserImageProps = {
  image: RvG.Contentful.IAsset
  mobileImage: RvG.Contentful.IAsset
  focalPoint?: RvG.Contentful.imageFocalPoint
}

type TeaserContentProps = {
  headline: string
  position: 'left' | 'center' | 'right'
  light?: boolean
  copy: RvG.Contentful.BasicRichTextType
  cta?: RvG.Contentful.BasicRichTextType
  ctaButton?: React.ReactNode
}

function TeaserImage({ image, mobileImage, focalPoint }: TeaserImageProps) {
  const classes = useStyles()
  return (
    <div className={clsx(classes.imageWrapper)}>
      <Image
        className={classes.image}
        image={image.fluid}
        imageMobile={mobileImage?.fluid}
        focalPoint={focalPoint}
        imgStyle={{ margin: '0' }}
      />
    </div>
  )
}

function TeaserContent({
  headline,
  copy,
  cta,
  ctaButton,
  light,
  position,
}: TeaserContentProps) {
  const classes = useStyles()
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  return (
    <div
      ref={ref}
      className={clsx(classes.contentWrapper, {
        [classes.inView]: inView,
      })}
    >
      <Box className={classes.box} size="l" position={position} light={light}>
        <div className={clsx(classes.contentInner)}>
          <Headline className={classes.headlineAnim}>{headline}</Headline>
          <Copy
            className={clsx(classes.copy, classes.copyAnim)}
            richtext={copy}
            ariaRole="paragraph"
          />
          {cta && (
            <Copy
              className={clsx(classes.cta, classes.ctaAnim)}
              richtext={cta}
            />
          )}
          {ctaButton && (
            <div className={clsx(classes.cta, classes.ctaAnim)}>
              {ctaButton}
            </div>
          )}
        </div>
      </Box>
    </div>
  )
}

export default function BigTeaserDefault({
  layout,
  headline,
  copy,
  cta,
  ctaButton,
  imageFocalPoint,
  image,
  mobileImage,
  light,
}: BigTeaserProps): ReactElement {
  const classes = useStyles()
  const isTrailing = layout === 'Trailing'
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  function Element() {
    return isTrailing ? (
      <>
        <TeaserContent
          headline={headline}
          copy={copy}
          cta={cta}
          ctaButton={ctaButton}
          light={light}
          position={isTrailing ? 'right' : 'left'}
        />
        <TeaserImage
          image={image}
          mobileImage={mobileImage}
          focalPoint={imageFocalPoint}
        />
      </>
    ) : (
      <>
        <TeaserImage
          image={image}
          mobileImage={mobileImage}
          focalPoint={imageFocalPoint}
        />
        <TeaserContent
          headline={headline}
          copy={copy}
          cta={cta}
          ctaButton={ctaButton}
          light={light}
          position={isTrailing ? 'right' : 'left'}
        />
      </>
    )
  }

  return (
    <div className={clsx(classes.root, { isTrailing })}>
      {isSmall ? (
        <Element />
      ) : (
        <Container className={classes.container} type="nomargin">
          <Element />
        </Container>
      )}
    </div>
  )
}
