import React, { ReactElement, useRef, useEffect } from 'react'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import Background from './components/background'
import TopArea from './components/topArea'
import Content from './components/content'
import TeaserRow, { RelatedContentTypes } from '@components/teaserRow'

const useStyles = makeStyles(() => ({
  root: {},
  hidden: {
    opacity: 0,
  },
}))

export type ScrollytellingProps = RvG.IReactDefaultProps & {
  background?: RvG.Contentful.IAsset
  backgroundMobile?: RvG.Contentful.IAsset
  copy?: RvG.Contentful.BasicRichTextType
  copyPosition?: 'Left' | 'Center' | 'Right'
  copySize?: 'Small' | 'Medium' | 'Large'
  scrollDownLabel?: string
  soundFile?: RvG.Contentful.IAsset
  soundLabelOn?: string
  soundLabelOff?: string
  contentBackground?: RvG.Contentful.IAsset
  contentBackgroundMobile?: RvG.Contentful.IAsset
  content: RvG.Contentful.ContentfulModuleScrolltytellingContent[]
  relatedContentHeadline?: string
  relatedContent?: RelatedContentTypes[]
}

export default function Scrollytelling({
  background,
  backgroundMobile,
  copy,
  copyPosition,
  copySize,
  scrollDownLabel,
  soundFile,
  soundLabelOff,
  soundLabelOn,
  contentBackground,
  contentBackgroundMobile,
  content,
  relatedContentHeadline,
  relatedContent,
}: RvG.Contentful.IContentfulModuleScrolltytelling): ReactElement {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const classes = useStyles({ isDarkTheme: false })
  const contentRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const videoRef = useRef<HTMLVideoElement>(null)
  const [show, setShow] = React.useState(false)

  function scrollTo(element: HTMLInputElement) {
    window.scrollTo({
      top:
        element.getBoundingClientRect().y +
        window.pageYOffset -
        (isLarge ? 64 : 0),
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    function onScroll() {
      if (videoRef?.current) {
        videoRef.current.play()
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  function onThumbnailClick() {
    if (videoRef?.current) {
      videoRef.current.pause()
    }
  }

  function onLoad() {
    // TopArea background is loaded
    setShow(true)
  }

  return (
    <section className={classes.root}>
      <TopArea
        copy={copy}
        copyPosition={copyPosition ?? 'Left'}
        copySize={copySize ?? 'Small'}
        scrollDown={() => scrollTo(contentRef.current)}
        scrollDownLabel={scrollDownLabel}
        soundFile={soundFile}
        soundLabelOff={soundLabelOff}
        soundLabelOn={soundLabelOn}
        loaded={show}
      >
        {!!background && (
          <Background
            video={background}
            videoMobile={backgroundMobile}
            full={!isLarge}
            onLoad={onLoad}
          />
        )}
      </TopArea>

      <div ref={contentRef} className={!show ? classes.hidden : ''}>
        <Content content={content} onThumbnailClick={onThumbnailClick}>
          {!!contentBackground && (
            <Background
              ref={videoRef}
              video={contentBackground}
              videoMobile={contentBackgroundMobile}
              full
              content
            />
          )}
        </Content>
      </div>

      {relatedContent && relatedContent?.length > 0 && (
        <TeaserRow
          type="Default"
          theme="Dark"
          headline={relatedContentHeadline || ''}
          relatedContent={relatedContent}
        />
      )}
    </section>
  )
}
